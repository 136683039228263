import { commonConstants } from '../constants';

export function common(state , action) {
  switch (action.type) {
    case commonConstants.GET_WILAYAS_REQUEST:
      return {
        GetingWilaya:"0",
        dataWilaya: []
      };
    case commonConstants.GET_WILAYAS_SUCCESS:
      return {
        GetingWilaya:"1",
        dataWilaya: action.payload
      };
    case commonConstants.GET_WILAYAS_FAILURE:
      return {
        GetingWilaya:"2",
        dataWilaya: action.payload
      };
    case commonConstants.GET_COMMUNE_REQUEST:
    return {
        ...state,
        GetigCommune:"0",
        DataCommune: action.payload
    };
    case commonConstants.GET_COMMUNE_SUCCESS:
      return {
        ...state,
        GetigCommune:"1",
        DataCommune: action.payload
      };
    case commonConstants.GET_COMMUNE_FAILURE:
      return {
        ...state,
        GetigCommune:"2",
        DataCommune: action.payload
      };
    case commonConstants.GET_WAREHOUSE_REQUEST:
      return {
        GetingWare:"1",
        Waredata: action.payload
      };
    case commonConstants.GET_WAREHOUSE_SUCCESS:
      return {
        GetingWare:"2",
        Waredata: action.payload
      };
    case commonConstants.GET_WAREHOUSE_FAILURE:
      return {
        GetingWare:"3",
        Waredata: action.payload
      };
    case commonConstants.GET_DELIVERY_REQUEST:
      return {
        ...state,
        GetingDelivery:"1",
        dataDelivery: action.payload
      };
    case commonConstants.GET_DELIVERY_SUCCESS:
      return {
        ...state,
        GetingDelivery:"2",
        dataDelivery: action.payload
      };
    case commonConstants.GET_DELIVERY_FAILURE:
      return {
        ...state,
        GetingDelivery:"3",
        dataDelivery: action.payload
      };
    case commonConstants.GET_AGENTS_LIST_REQUEST:
      return {
          ...state,
          GettingAgents:"0",
          AgentsList: action.payload
      };
    case commonConstants.GET_AGENTS_LIST_SUCCESS:
      return {
        ...state,
        GettingAgents:"1",
        AgentsList: action.payload
      };
    case commonConstants.GET_AGENTS_LIST_FAILURE:
      return {
        ...state,
        GettingAgents:"2",
        AgentsList: action.payload
      };

    case commonConstants.GET_STORES_LIST_REQUEST:
      return {
          ...state,
          GettingStores:"0",
          StoresList: action.payload
      };
    case commonConstants.GET_STORES_LIST_SUCCESS:
      return {
        ...state,
        GettingStores:"1",
        StoresList: action.payload
      };
    case commonConstants.GET_STORES_LIST_FAILURE:
      return {
        ...state,
        GettingStores:"2",
        StoresList: action.payload
      };
    case "CLEAR_COMMON":
      return {}
    default:
        return {...state}
    }
}