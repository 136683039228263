import { Translate } from "src/utils/lang/translate";
import services from "../../services/CallSession/CallSessionServices";

export const onSuccess = async (params, snackMessage) => {
  params?.setIsBackDropOpen(false);
  params?.setIsOpenSnackBar(true);
  params?.setSnackBarMessage(snackMessage);
  params?.setSnackBarSeverity("success");
  await params?.setOrder([]);
  await params?.resetOrderAfterUpdate();
};
export const onError = (params, snackBarMessage) => {
  params?.setIsBackDropOpen(false);
  params?.setIsOpenSnackBar(true);
  params?.setSnackBarMessage(snackBarMessage);
  params?.setSnackBarSeverity("error");
};
export const canConfirm = (order, setValidationErrors) => {
  if (!order?.wilaya) {
    setValidationErrors((current) => {
      return {
        ...current,
        wilaya: Translate("callsession", "wilaya-error"),
      };
    });
    return false;
  } else {
    setValidationErrors((current) => {
      return {
        ...current,
        wilaya: "",
      };
    });
  }

  if (!order?.commune) {
    setValidationErrors((current) => {
      return {
        ...current,
        commune: Translate("callsession", "commune-error"),
      };
    });
    return false;
  } else {
    setValidationErrors((current) => {
      return {
        ...current,
        commune: "",
      };
    });
  }
  if (!order?.customer_address) {
    setValidationErrors((current) => {
      return {
        ...current,
        customer_address: Translate("callsession", "address-error"),
      };
    });
    return false;
  } else {
    setValidationErrors((current) => {
      return {
        ...current,
        customer_address: "",
      };
    });
  }
  return true;
};

export const getAssignedOrders = async (phone, params) => {
  let response = {};
  let data = {};
  let error = {};
  params?.setIsBackDropOpen(true);
  try {
    response = await services.getAssignedOrders(phone);
    if (response?.data) {
      params?.setAssignedOrders(response?.data);
      params?.setIsBackDropOpen(false);
      data = response?.data;
    }
  } catch (e) {
    params?.setIsBackDropOpen(false);
    error = e;
  }
  return { data, error };
};

export const getNextOrder = async (params) => {
  let response = {};
  let data = {};
  let error = {};
  try {
    response = await services.getNextOrder();
    if (response?.data) {
      data = response?.data;
      params?.setIsLoading(false);
    }
  } catch (err) {
    error = err;
    params?.setIsLoading(false);
  }

  return { data, error };
};

export const confirmOrder = async (orderId, order, params) => {
  let response = [];
  let error = [];
  let hasBeenConfirmed = false;
  if (!canConfirm(order, params?.setValidationErrors)) {
    window.location.href = "#delivery-details-anchor";

    return;
  }
  params?.setIsBackDropOpen(true);
  try {
    response = await services.confirmOrder(orderId, order);
    if (response) {
      window.location.href = "#callanchor";
      hasBeenConfirmed = true;
      params?.setIsBackDropOpen(false);
      params?.setIsOpenSnackBar(true);
      params?.setSnackBarMessage(Translate("callsession", "confirm-success"));
      params?.setSnackBarSeverity("success");
      params?.setValidationErrors((current) => {
        return {
          ...current,
          wilaya: "",
          commune: "",
        };
      });
      await params?.setOrder([]);
      await params?.resetOrderAfterUpdate();
    }
  } catch (err) {
    error = err;
    params?.setIsBackDropOpen(false);
    params?.setIsOpenSnackBar(true);
    params?.setSnackBarMessage(Translate("callsession", "confirm-error"));
    params?.setSnackBarSeverity("error");
    params?.setValidationErrors((current) => {
      return {
        ...current,
        wilaya: "",
        commune: "",
      };
    });
    hasBeenConfirmed = false;
  }

  return { response, error, hasBeenConfirmed };
};

export const callbackOrder = async (orderId, order, params) => {
  let response = [];
  let error = [];
  let hasBeenCallback = false;
  params?.setIsBackDropOpen(true);
  try {
    response = await services.callbackOrder(orderId, order);
    if (response) {
      hasBeenCallback = true;
      onSuccess(params, Translate("callsession", "callback-success"));
      hasBeenCallback = true;
    }
  } catch (err) {
    error = err;
    if (error) {
      onError(params, Translate("callsession", "callback-error"));
    }
  }

  return { response, error, hasBeenCallback };
};

export const cancelOrder = async (orderId, order, params) => {
  let response = {};
  let error = {};
  let hasBeenCancelled = false;
  if (!order.cancellation_reason) {
    params?.setValidationErrors((current) => {
      return {
        ...current,
        cancelReason: Translate("callsession", "cancel-error"),
      };
    });
    return;
  }
  params?.setIsBackDropOpen(true);
  try {
    response = await services.cancelOrder(orderId, order);
    if (response) {
      hasBeenCancelled = true;
      onSuccess(params, Translate("callsession", "cancel-success"));
      params?.setOpenCancelModal(false);
      params?.setValidationErrors(null);
    }
  } catch (err) {
    error = err;
    onError(params, Translate("callsession", "cancel-error"));
    params?.setValidationErrors(null);
  }

  return { response, error, hasBeenCancelled };
};

export const postponeOrder = async (orderId, order, params) => {
  let response = {};
  let error = {};
  let hasBeenPostPoned = false;
  if (!order.postponed_to) {
    params?.setValidationErrors((current) => {
      return {
        ...current,
        postponeDate: "Select a date",
      };
    });
    return;
  }
  params?.setIsBackDropOpen(true);

  try {
    response = await services.postponeOrder(orderId, order);
    if (response) {
      hasBeenPostPoned = true;
      onSuccess(params, Translate("callsession", "postpone-success"));
      params?.setOpenPostponeModal(false);
      params?.setValidationErrors(null);
    }
  } catch (e) {
    error = e;
    onError(params, Translate("callsession", "postpone-order-error"));
    params?.setValidationErrors(null);
  }

  return { response, error, hasBeenPostPoned };
};

const callSessionController = {
  getAssignedOrders,
  getNextOrder,
  confirmOrder,
  callbackOrder,
  cancelOrder,
  postponeOrder,
};

export default callSessionController;
