import axios from "axios";
import Cookies from "js-cookie";

// export const BACKEND_API_URL='https://b.maystro-delivery.com/api/';

// export const API_URL="http://34.79.49.61:8080/"
// export const API_URL="https://django-dev-nyg42cuu7q-ew.a.run.app/api/"
// export const API_URL="https://B.maystro-delivery.com/api/"
// export const API_URL="http://localhost:8080/"

// Dev URL
// export const BACKEND_API_URL='https://django-dev-nyg42cuu7q-ew.a.run.app/api/';
// export const API_URL="https://django-dev-nyg42cuu7q-ew.a.run.app/api/"

// Prod URL
//export const BACKEND_API_URL = "https://b.maystro-delivery.com/api/";
//export const API_URL = "https://b.maystro-delivery.com/api/";
//export const API_URL_DEV = "https://django-dev-nyg42cuu7q-ew.a.run.app/api/";

export const API_URL = "https://backenddev-new.maystro-services.com/api/";
export const BACKEND_API_URL = "https://backenddev-new.maystro-services.com/api/";
export const API_URL_DEV = "https://backenddev-new.maystro-services.com/api/";

window.axios = axios;

export const RequestService = {
  GetRequest,
  PostRequest,
  PatchRequest,
  DeleteRequest,
  PutRequest,
};
export function AuthHeader() {
  let token = Cookies.get("auth");
  return { Authorization: "Token " + token };
}

function GetRequest(url, headers, prefix = undefined) {
  // return axios.get(`${url}`, {
  return axios.get(`${prefix || BACKEND_API_URL}${url}`, {
    // httpsAgent: agent,
    headers: { "Content-Type": "application/json", ...headers },
  });
}

function PostRequest(url, data, headers, prefix = undefined) {
  return axios.post(`${prefix || BACKEND_API_URL}${url}`, data, {
    headers: { "Content-Type": "application/json", ...headers },
  });
}
function PatchRequest(url, data, headers, prefix = undefined) {
  return axios.patch(`${prefix || BACKEND_API_URL}${url}`, data, {
    headers: { "Content-Type": "application/json", ...headers },
  });
}
function DeleteRequest(url, headers, prefix = undefined) {
  return axios.delete(`${prefix || BACKEND_API_URL}${url}`, {
    headers: { "Content-Type": "application/json", ...headers },
  });
}
function PutRequest(url, data, headers, prefix = undefined) {
  return axios.put(`${prefix || BACKEND_API_URL}${url}`, data, {
    headers: { "Content-Type": "application/json", ...headers },
  });
}
