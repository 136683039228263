import axios from "axios";

export default axios.create({
  // baseURL: URL, //`https://b.maystro-delivery.com/api/`,
  baseURL: `https://backenddev-new.maystro-services.com/api/`,
  // baseURL: `https://django-dev-nyg42cuu7q-ew.a.run.app/api/`,
  headers: {
    "Content-type": "application/json",
  },
});
