import React, { useState, useEffect } from "react";
import "./CallSession-refactored.scss";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { red } from "@mui/material/colors";
import RemoveIcon from "@mui/icons-material/Remove";
import Backdrop from "@mui/material/Backdrop";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBar from "src/components/SnackBar/SnackBar";
import Modal from "@mui/material/Modal";
import callSessionController from "../../../controllers/Agent/CallSession.controller";
import globalController from "src/controllers/global/global.controller";
import Virtualize from "./virtualize";
import { CallBar } from "../../../components/custom/CallBar";
import CancelOrderPopup from "src/components/agent/callsession/CancelOrderPopup";
import PostponeOrderPopup from "src/components/agent/callsession/PostponeOrderPopup";
import { Translate } from "../../../utils/lang/translate";
import { noMoreOrdersIllustration } from "src/constants/svg/Callsession/svg-illustrations";
import DropDown from "./components/DropDown";

import { orderActions } from "../../../redux/actions/";

import { connect } from "react-redux";
import { commonActions } from "src/redux/actions/common.actions";
import { userActions } from "src/redux/actions/user.actions";

const CallSessionRefactored = (props) => {
  // Main state
  const [order, setOrder] = useState([]);
  const [country_id_, setCountryid] = useState();

  const [isLoading, setIsLoading] = useState(true);
  // assignedOrders state
  const [assignedOrders, setAssignedOrders] = useState([{}]);
  // Wilayas state
  const [wilayas, setWilayas] = useState([{}]);
  // Communes state
  const [communes, setCommunes] = useState([{}]);
  // Cancel order Modal state
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const handleOpenCancelModal = () => setOpenCancelModal(true);
  const handleCloseCancelModal = () => setOpenCancelModal(false);
  // Postpone order Modal state
  const [openPostponeModal, setOpenPostponeModal] = useState(false);
  const handleOpenPostponeModal = () => setOpenPostponeModal(true);
  const handleClosePosponeModal = () => setOpenPostponeModal(false);
  // Cancellation reasons state
  const [cancellationReasons, setCancellationReasons] = useState([{}]);
  // Search assigned orders state
  const [searchByPhoneValue, setSearchByPhoneValue] = useState(null);
  // Backdrop state
  const [isBackDropOpen, setIsBackDropOpen] = useState(false);
  // SnackBar state
  const [isOpenSnackBar, setIsOpenSnackBar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const [selectProduct, setSelectProduct] = useState("");
  const [allProduct, setAllProduct] = useState();

  const [notetodrivers, setNoteToDrivers] = useState();

  useEffect(() => {
    if (!order.details) {
      setOrder({
        ...order,
        details: [
          {
            product: order.product_name,
            quantity: order.product_quantity,
          },
        ],
      });
    }
  }, [order]);

  const addInputField = () => {
    setOrder({
      ...order,
      details: [
        ...order.details,
        {
          product: "Choose a product",
          quantity: 1,
        },
      ],
    });
  };

  const removeInputFields = (index) => {
    const rows = [...order.details];
    rows.splice(index, 1);
    setOrder({
      ...order,
      details: rows,
    });
  };

  const handleChangenameProduct = (index, evnt) => {
    const { name, value } = evnt.target;
    if ((name === "product" || name === "qte") && typeof value === "string") {
      const list = order.details.map((field, key) => {
        if (key !== index) return field;
        return { ...field, [name]: value };
      });
      setOrder({
        ...order,
        details: list,
      });
    }
  };

  // Validation state
  const [validationErrors, setValidationErrors] = useState({
    wilaya: "",
    commune: "",
  });
  // Test state

  React.useEffect(() => {
    setIsBackDropOpen(true);

    const handleBackDropClose = () => {
      setIsBackDropOpen(false);
    };

    const fetchData = async (callback) => {
      if (isLoading) {
        const getNextOrder_params = {
          setIsLoading,
        };

        const getNextOrder = callSessionController.getNextOrder;
        const getWilayas = globalController.getAllWilayas;
        const getCommunes = globalController.getAllCommunes;
        const getCancellationReasons = globalController.getCancellationReasons;

        // const fetchedWilayasResponse = await getWilayas(); // Comment.2
        const country_id = props?.dataProfileUser?.country;

        if (country_id) {
          props.Getwilayas(country_id);
        }

        const fetchedCommunesResponse = await getCommunes(); // Comment.3
        const fetchedCancellationReasonsResponse = await getCancellationReasons({ setCancellationReasons });

        await getNextOrder(getNextOrder_params).then((nextOrderResponse) => {
          if (nextOrderResponse?.data) {
            setOrder({ ...nextOrderResponse.data });
          } else {
            setOrder(null);
          }
        }); // Comment.1

        setWilayas(props?.dataWilaya);
        setCommunes(fetchedCommunesResponse?.data);
        setCancellationReasons(fetchedCancellationReasonsResponse?.list);
      }
      callback();
    };
    fetchData(handleBackDropClose);
  }, [isLoading]);

  const handleBackDropClose = () => {
    setIsBackDropOpen(false);
  };
  const handleAddQuantity = (productName) => {
    setOrder({
      ...order,
      details: order.details.map((elem) => {
        if (elem.product === productName) return { ...elem, quantity: elem.quantity + 1 };
        else return elem;
      }),
    });
  };
  const handleSubtractQuantity = (productName) => {
    setOrder({
      ...order,
      details: order.details.map((elem) => {
        if (elem.product === productName) return { ...elem, quantity: elem.quantity - 1 };
        else return elem;
      }),
    });
  };
  const handleChangeQuantity = (e, productName) => {
    const newQuantity = e.target.value;
    setOrder({
      ...order,
      details: order.details.map((elem) => {
        if (elem.product === productName) return { ...elem, quantity: newQuantity };
        else return elem;
      }),
    });
  };
  const handleChangePrice = (event) => {
    const newPrice = event.target.value || "";
    setOrder((current) => {
      return { ...current, total_price: newPrice };
    });
  };

  const handleChangeAddress = (event) => {
    const newAddress = event.target.value || "";

    setOrder((current) => {
      return { ...current, customer_address: newAddress };
    });
  };

  const handleSelectWilaya = (event, selected) => {
    const newWilaya_id = selected?.value || null;
    const newWilaya_nameWithId = selected?.label || null;
    const newWilaya_name = newWilaya_nameWithId?.split(" ")[0];

    setOrder((current) => {
      return {
        ...current,
        wilaya: newWilaya_id,
        upload_wilaya_name: newWilaya_name,
      };
    });
  };

  const handleSelectCommune = (event, selected) => {
    const newCommune_nameWithId = selected?.label || null;
    const newCommune_id = selected?.value || null;
    const newCommune_name = newCommune_nameWithId?.split(" ")[0];
    setOrder((current) => {
      return {
        ...current,
        commune: newCommune_id,
        upload_commune_name: newCommune_name,
      };
    });
  };

  const handleNoteToDriver = (event) => {
    const noteToDriver = event.target.value || "";
    setOrder((current) => {
      return {
        ...current,
        note_to_driver: noteToDriver,
      };
    });
    setNoteToDrivers(event.target.value || "");
  };

  const resetOrderAfterUpdate = async () => {
    const getNextOrder_params = {
      setIsLoading,
      setIsBackDropOpen,
    };

    const getNextOrder = callSessionController.getNextOrder;

    await getNextOrder(getNextOrder_params)
      .then((nextOrderResponse) => {
        if (nextOrderResponse?.data) {
          setOrder({ ...nextOrderResponse.data });
        } else {
          setOrder(null);
        }
      })
      .then(() => {
        handleBackDropClose();
        setAssignedOrders([]);
      });
  };
  const handleConfirmOrder = (event) => {
    event.preventDefault();
    const confirmOrder = callSessionController.confirmOrder;
    const orderId = order?.id;
    const confirmedOrder = {
      ...order,
    };

    confirmOrder(orderId, confirmedOrder, {
      setIsBackDropOpen,
      setIsOpenSnackBar,
      setSnackBarMessage,
      setSnackBarSeverity,
      setValidationErrors,
      setOrder,
      resetOrderAfterUpdate,
    }).then(() => {
      handleBackDropClose();
    });
    setNoteToDrivers("");
  };
  const handleCancelOrder = (event) => {
    event.preventDefault();
    const orderId = order?.id;
    const cancelOrder = callSessionController.cancelOrder;
    cancelOrder(orderId, order, {
      setIsBackDropOpen,
      setIsOpenSnackBar,
      setSnackBarMessage,
      setSnackBarSeverity,
      setValidationErrors,
      setOrder,
      resetOrderAfterUpdate,
      setOpenCancelModal,
    }).then(() => {
      window.location.href = "#callanchor";
    });
    setNoteToDrivers("");
  };

  const handleCallbackOrder = (event) => {
    event.preventDefault();
    const orderId = order?.id;
    const callbackOrder = callSessionController.callbackOrder;

    callbackOrder(orderId, order, {
      setIsBackDropOpen,
      setIsOpenSnackBar,
      setSnackBarMessage,
      setSnackBarSeverity,
      setOrder,
      resetOrderAfterUpdate,
    }).then(() => {
      window.location.href = "#callanchor";
    });
    setNoteToDrivers("");
  };

  const handlePostponeOrder = (event) => {
    event.preventDefault();
    const orderId = order?.id;

    const posteponeOrder = callSessionController.postponeOrder;
    posteponeOrder(orderId, order, {
      setIsBackDropOpen,
      setIsOpenSnackBar,
      setSnackBarMessage,
      setSnackBarSeverity,
      setOpenPostponeModal,
      setValidationErrors,
      setOrder,
      resetOrderAfterUpdate,
    }).then(() => {
      window.location.href = "#callanchor";
    });
    setNoteToDrivers("");
  };
  const handleAssignedOrdersPhoneChange = (event) => {
    const phoneNumber = event.target.value || "";
    setSearchByPhoneValue(phoneNumber);
  };

  const handleGetAssignedOrders = () => {
    const getAssignedOrders = callSessionController.getAssignedOrders;
    getAssignedOrders(searchByPhoneValue, {
      setAssignedOrders,
      setIsBackDropOpen,
    });
  };

  const handleSelectAssignOrder = (newOrder) => {
    setIsBackDropOpen(true);
    window.location.href = "#callanchor";
    setTimeout(() => {
      setOrder({
        ...newOrder,
        wilaya: newOrder?.wilaya || null,
        commune: newOrder?.commune || null,
        upload_commune_name: newOrder?.upload_commune_name || null,
        upload_wilaya_name: newOrder?.upload_wilaya_name || null,
      });
      setIsBackDropOpen(false);
    }, 500);
  };
  const storeId = order?.store?.id;

  useEffect(() => {
    props.GetProductStore(storeId);
  }, [storeId]);

  const { ProductsList } = props;
  useEffect(() => {
    if (props.GettingProducts == 1) {
      setAllProduct(props.ProductsList);
    } else {
      return null;
    }
  }, [props.GettingProducts, props.ProductsList]);

  useEffect(() => {
    props.GetDataUser();
  }, []);

  const country_id = props?.dataProfileUser?.country;
  useEffect(() => {
    if (country_id) {
      props.Getwilayas(country_id);
    }
  }, [country_id]);

  return (
    <div className="CallSessionRefactored">
      <SnackBar openSnackBar={{ isOpenSnackBar, setIsOpenSnackBar }} snackBarSeverity={snackBarSeverity} snackBarMessage={snackBarMessage} />
      <Backdrop sx={{ zIndex: 1000 }} open={isBackDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal open={openCancelModal} onClose={handleCloseCancelModal} id="cancelOrder-modal">
        <CancelOrderPopup cancellationReasons={cancellationReasons} setOrder={setOrder} handleCancelOrder={handleCancelOrder} validationErrors={validationErrors} />
      </Modal>

      <Modal open={openPostponeModal} onClose={handleClosePosponeModal} id="postpone-modal">
        <PostponeOrderPopup order={order} setOrder={setOrder} handlePostponeOrder={handlePostponeOrder} validationErrors={validationErrors} setValidationErrors={setValidationErrors} />
      </Modal>
      {order ? (
        <>
          <main>
            <section className="CallSession_productsAndOrders">
              <article className="productDetails">
                <h4>{Translate("orders", "productlist")}</h4>

                <section className="productDetails_productsList">
                  {order?.details &&
                    order.details.map((data, index) => {
                      const { product, quantity } = data;
                      return (
                        <article key={index} className="productList_product">
                          <section className="productList_product_quantity">
                            <TextField
                              id="product-quantity-input"
                              className="productQuantityInput"
                              type="number"
                              name="qte"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="standard"
                              onChange={(evnt) => handleChangeQuantity(evnt, product)}
                              value={quantity}
                            />
                            {/* <b>{order?.product_quantity}</b> */}
                            <section className="quantity_operations">
                              <AddIcon onClick={() => handleAddQuantity(product)} />

                              <RemoveIcon onClick={() => handleSubtractQuantity(product)} />
                            </section>
                          </section>
                          <section className="productList_product_description">
                            <label htmlFor="product-quantity-input">
                              {/*order?.product_name*/}
                              {order.details.length !== 1 ? <DeleteIcon style={{ color: "red" }} onClick={removeInputFields} /> : ""}
                              <DropDown
                                name="product"
                                productOrder={product}
                                options={allProduct
                                  ?.filter(
                                    (prod) =>
                                      !order?.details.some((prod2) => {
                                        if (product === prod.logistical_description) return false;
                                        return prod.logistical_description === prod2.product;
                                      })
                                  )
                                  .concat([
                                    {
                                      logistical_description: "Choose a product",
                                      quantity: 0,
                                    },
                                  ])}
                                onChange={(evnt) => handleChangenameProduct(index, evnt)}
                              />
                            </label>
                          </section>
                        </article>
                      );
                    })}
                  <button
                    style={{
                      backgroundColor: "#008aff",
                      borderColor: "#008aff",
                      color: "white",
                      display: "flex",
                      cursor: "pointer",
                      width: "130px",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                    onClick={addInputField}
                  >
                    Add Product
                  </button>
                </section>
              </article>

              <article id="callanchor" className="ordersDetails">
                <h4>{Translate("orders", "orderdetail")}</h4>
                <section className="ordersDetails_container">
                  <section className="ordersDetails_id">
                    <h4>{Translate("orders", "orderid")} :</h4>
                    <h3>{order?.external_id}</h3>
                  </section>
                  <section className="ordersDetails_store">
                    <h4>{Translate("orders", "storename")} :</h4>
                    <h3>{order?.store?.name}</h3>
                  </section>
                  <section className="ordersDetails_price">
                    {/* <h4>Price :</h4> */}
                    <label htmlFor="price-input">{Translate("orders", "price")}</label>
                    <OutlinedInput
                      id="price-input"
                      label="Price"
                      className="priceInput"
                      type="number"
                      // value={moneyFormatter_DZD(order?.total_price)}
                      value={order?.total_price}
                      onChange={handleChangePrice}
                      endAdornment={
                        props?.dataProfileUser?.country === 1 ? (
                          <InputAdornment className="priceInput_DA" position="end">
                            DA
                          </InputAdornment>
                        ) : (
                          <InputAdornment className="priceInput_DA" position="end">
                            DT
                          </InputAdornment>
                        )
                      }
                      min={1}
                    />
                    {/* <h3>{moneyFormatter_DZD(order?.total_price)}</h3> */}
                  </section>
                </section>
              </article>
            </section>
            <section className="CallSession_call">
              <CallBar
                customer={{
                  customer_name: order?.customer_name,
                  customer_phone: order?.customer_phone,
                }}
              />
            </section>
            <section className="CallSession_deliveryAndActions">
              <article id="delivery-details-anchor" className="deliveryDetails">
                <h4>{Translate("orders", "deliverydetails")}</h4>
                <section className="deliveryDetails_container">
                  <section className="deliveryDetails_left">
                    <section className="address_container">
                      {/* <h6>Address</h6> */}
                      <Input id="address-input" className="input_textfield" placeholder={Translate("callsession", "address-label")} value={order?.customer_address} onChange={handleChangeAddress} />
                      {validationErrors?.customer_address && <label htmlFor="address-input">{validationErrors?.customer_address}</label>}
                    </section>
                    <section className="wilaya_commune_container">
                      <Autocomplete
                        id="wilaya-input"
                        className="input_autocomplete"
                        disablePortal
                        options={props?.dataWilaya?.map((wilaya, index) => {
                          return {
                            value: wilaya[0],
                            label: `${wilaya[1]} - ${wilaya[0]}`,
                          };
                        })}
                        sx={{ width: 250 }}
                        renderInput={(params) => <TextField {...params} label={Translate("callsession", "wilaya")} />}
                        onChange={handleSelectWilaya}
                      />
                      {validationErrors?.wilaya && <label htmlFor="wilaya-input">{validationErrors?.wilaya}</label>}
                      {/* <h6>Commune</h6> */}

                      <Virtualize
                        id="commune-input"
                        data={communes
                          .filter((commune, index) => {
                            if (order?.wilaya && commune?.wilaya === order?.wilaya) return commune;
                            else return null;
                          })
                          .map((commune, index) => {
                            return {
                              value: commune?.id,
                              label: `${commune?.name} - ${commune?.wilaya}`,
                            };
                          })}
                        width={250}
                        onChange={handleSelectCommune}
                        label={Translate("callsession", "commune")}
                      />
                      {validationErrors?.commune && <label htmlFor="commune-input">{validationErrors?.commune}</label>}
                    </section>
                  </section>
                  <section className="deliveryDetails_right">
                    <section>
                      <h6>{Translate("orders", "notetodriver")}</h6>
                      <textarea name="note-to-driver" value={notetodrivers} onChange={handleNoteToDriver}></textarea>
                    </section>
                  </section>
                </section>
              </article>
              <article className="actions">
                <h4>{Translate("titles", "actions")}</h4>
                <section className="actions_buttonContainer">
                  <button id="confirm-button" onClick={handleConfirmOrder} disabled={order?.details?.some((elem) => elem.product === "Choose a product")}>
                    {Translate("orders", "confirmorder")}
                  </button>
                  <button id="cancel-button" onClick={handleOpenCancelModal}>
                    {Translate("orders", "cancelorder")}
                  </button>
                  <button id="callback-button" onClick={handleCallbackOrder}>
                    {Translate("actions", "callback")}
                  </button>
                  <button id="postpone-button" onClick={handleOpenPostponeModal}>
                    {Translate("actions", "postpone")}
                  </button>
                </section>
              </article>
            </section>
          </main>
          <footer>
            <article className="CallSession_orderSearch">
              <section className="searchContainer">
                <TextField id="search-assigned-orders-input" label={Translate("orders", "search-by-phone")} className="pospontedOrderSearchInput" type="search" value={searchByPhoneValue} onChange={handleAssignedOrdersPhoneChange} />
                <button type="submit" onClick={handleGetAssignedOrders} className="searchContainer_button">
                  {Translate("callsession", "search")}
                </button>
              </section>
              <article className="postponedOrdersContainer">
                <table className="postponedOrdersTable">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{Translate("callsession", "product")}</th>
                      <th>{Translate("callsession", "customer")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedOrders &&
                      assignedOrders.map((order, index) => {
                        const status = order?.status;
                        const product = order?.product;
                        const customer = order?.customer_name;
                        const callBackColor = "#007bff";
                        const postponedColor = "rgb(164, 54, 172)";
                        const cancelledColor = "#dc3545";

                        const statusCircleBackGroundColor = status === "Callback" ? callBackColor : status === "Postponed" ? postponedColor : order === "Cancelled" ? cancelledColor : "";
                        return (
                          <tr key={index} onClick={() => handleSelectAssignOrder(order)}>
                            <td>
                              <div
                                className="statusColorCircle"
                                style={{
                                  backgroundColor: statusCircleBackGroundColor,
                                }}
                              ></div>
                            </td>
                            <td>
                              <div>{product}</div>
                            </td>
                            <td>{customer}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </article>
            </article>
          </footer>
        </>
      ) : (
        <article className="noMoreOrdersContainer">
          {noMoreOrdersIllustration}
          <h3>{Translate("callsession", "no-more-orders")}</h3>
        </article>
      )}
    </div>
  );
};

function mapState(state) {
  const { GettingProducts, ProductsList } = state.orders;
  const { GetingWilaya, dataWilaya } = state.common;
  const { FetchingUserData, dataProfileUser } = state.user;

  return {
    GettingProducts,
    ProductsList,
    GetingWilaya,
    dataWilaya,
    FetchingUserData,
    dataProfileUser,
  };
}

const actionCreators = {
  GetProductStore: orderActions.GetProductStore,
  Getwilayas: commonActions.Getwilayas,
  GetDataUser: userActions.GetDataUser,
};

const connectedCallSessionRefactored = connect(mapState, actionCreators)(CallSessionRefactored);
export { connectedCallSessionRefactored as CallSessionRefactored };

// Comments :
// -----------
// Comment.1 :
// Get the next order for the confirmation agent
// Comment.2
// Get the list of wilayas
// Comment.3
// Get the list of wilayas
