import React, { useEffect, useState } from "react";

import "./dropdown.css";

export default function DropDown(props) {
  const { options = [] } = props;

  const [productname, setProductName] = useState(props.productOrder);

  useEffect(() => {
    setProductName(props.productOrder);
  }, [props]);

  const productsList = options.map((data) => {
    return {
      key: data.id,
      value: data.logistical_description,
      product_name: data.name,
      quantity: data.quantity,
    };
  });

  const listItems = productsList.map((option, index) => {
    return (
      <option key={option.key} value={option.value}>
        {option.value} {">>>"} En stock : {option.quantity}
      </option>
    );
  });

  return (
    <select
      name={props.name}
      className="input-password-mobile-small input-password-mobile input"
      value={productname}
      onChange={props.onChange}
    >
      {listItems}
    </select>
  );
}
