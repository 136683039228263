import { BACKEND_API_URL, API_URL } from "../redux/services/request-service";
var MockAdapter = require("axios-mock-adapter");
var mock = new MockAdapter(window.axios, {
  delayResponse: 1000,
  onNoMatch: "passthrough",
});
const ADMIN_TOKENS=[
  "567222419ee12df40e34a4361c715703206e3608",
  "1c4ccbfea576b661b81774073d26586f37c0ef28",
  "9bd1cf74744511b1151e15ada3a9bab25aed738a",
  "02042ed4047197bc2f990beb3ea0079b5f040de4"

]
mock.onGet(`${BACKEND_API_URL}confirmation/profile/`).reply((config) => {
  return [
    200,
    {
      information: {
        username: "Yasser",
        picture: "/assets/img/user.png",
        full_name: "Yasser Drif",
      },
    },
  ];
});
mock.onPost(`${BACKEND_API_URL}confirmation/session/start/`).reply((config) => {
  return [
    200,
    {
      ok: true,
      start_date: new Date(),
      valid_duration: 8,
    },
  ];
});
mock.onPost(`${API_URL}admin/logino/`).reply((config) => {
  return [
    200,
    {
      success: true,
      agentId: 1,
      token: "bfdc78ab1be1e82cbe65c1ef5102fb878fb5213a",
    },
  ];
});
mock.onGet(/\/profile\/.*/).reply((config) => {
    const token=config.headers.Authorization.split("Token ")[1];
    let is_admin=ADMIN_TOKENS.indexOf(token)>-1
  
    return [
    200,
    {
      success: true,
      information: {
        username: is_admin?"Admin":"Agent",
        picture: "/assets/img/user.png",
        full_name: is_admin?"Admin":"Agent",
        is_admin: is_admin
      },
    },
  ];
});

mock.onPost(/\/confirmation\/session\/call\/.*/).reply((config) => {
  return [
    200,
    {
      ok: true,
    },
  ];
});
