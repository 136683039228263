import { commonConstants } from "../constants";
import { PUBLIC_TEST_TOKEN } from "../../config";
import { API_URL, RequestService, ResponseHandler } from "../services";
import Cookies from "js-cookie";
const token = Cookies.get("auth");

export const commonActions = {
  Getwilayas,
  Getcommunes,
  GetWarehouse,
  ClearStore,
  GetDelivery,
  ListStores,
  ListAgents,
};
function Getwilayas(country_id) {
  return (dispatch) => {
    dispatch(ResponseHandler.request(commonConstants.GET_WILAYAS_REQUEST));
    RequestService.GetRequest("shared/wilayas/?country_id=" + country_id)

      .then((res) => {
        dispatch(ResponseHandler.succes(res.data, commonConstants.GET_WILAYAS_SUCCESS));
      })
      .catch((err) => {
        dispatch(ResponseHandler.failure(err.response, commonConstants.GET_WILAYAS_FAILURE));
      });
  };
}
function Getcommunes(param) {
  return (dispatch) => {
    dispatch(ResponseHandler.request(commonConstants.GET_COMMUNE_REQUEST));
    let url = "shared/communes/" + param;
    RequestService.GetRequest(url, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(ResponseHandler.succes(res.data, commonConstants.GET_COMMUNE_SUCCESS));
      })
      .catch((err) => {
        dispatch(ResponseHandler.failure(err.response, commonConstants.GET_COMMUNE_FAILURE));
      });
  };
}
function GetWarehouse() {
  return (dispatch) => {
    dispatch(ResponseHandler.request(commonConstants.GET_WAREHOUSE_REQUEST));
    RequestService.GetRequest("stores/add_warehouse/", {
      Authorization: "Token " + PUBLIC_TEST_TOKEN,
    })
      .then((res) => {
        dispatch(ResponseHandler.succes(res.data, commonConstants.GET_WAREHOUSE_SUCCESS));
      })
      .catch((err) => {
        dispatch(ResponseHandler.failure(err.response, commonConstants.GET_WAREHOUSE_FAILURE));
      });
  };
}
function ClearStore(DeletConst) {
  return (dispatch) => {
    dispatch(clear(DeletConst));
  };
  function clear(DeletConst) {
    return {
      type: DeletConst,
      payload: {},
    };
  }
}

function GetDelivery(commune, express) {
  return (dispatch) => {
    dispatch(ResponseHandler.request(commonConstants.GET_DELIVERY_REQUEST));
    let url = "stores/delivery_price/?commune=" + commune + "&express=" + express;
    RequestService.GetRequest(url, {
      Authorization: "Token " + PUBLIC_TEST_TOKEN,
    })
      .then((res) => {
        dispatch(ResponseHandler.succes(res.data, commonConstants.GET_DELIVERY_SUCCESS));
      })
      .catch((err) => {
        dispatch(ResponseHandler.failure(err, commonConstants.GET_DELIVERY_FAILURE));
      });
  };
}

function ListStores() {
  return (dispatch) => {
    dispatch(ResponseHandler.request(commonConstants.GET_STORES_LIST_REQUEST));
    RequestService.GetRequest("confirmation/stores/", { Authorization: "Token " + token }, API_URL)
      .then((res) => {
        dispatch(ResponseHandler.succes(res.data, commonConstants.GET_STORES_LIST_SUCCESS));
      })
      .catch((err) => {
        dispatch(ResponseHandler.failure(err.response, commonConstants.GET_STORES_LIST_FAILURE));
      });
  };
}

function ListAgents() {
  return (dispatch) => {
    dispatch(ResponseHandler.request(commonConstants.GET_AGENTS_LIST_REQUEST));
    RequestService.GetRequest("confirmation/confirmation_agents/", { Authorization: "Token " + token }, API_URL)
      .then((res) => {
        dispatch(ResponseHandler.succes(res.data, commonConstants.GET_AGENTS_LIST_SUCCESS));
      })
      .catch((err) => {
        dispatch(ResponseHandler.failure(err.response, commonConstants.GET_AGENTS_LIST_FAILURE));
      });
  };
}
