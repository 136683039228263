import { userConstants } from "../constants";
import { API_URL, AuthHeader, RequestService, ResponseHandler } from "../services";
import Cookies from "js-cookie";
const token = Cookies.get("auth");
const store_id = Cookies.get("c_store");

export const userActions = {
  Login,
  Register,
  RegisterTap: () => null,
  Logout,
  GetProfileUser,
  GetDataUser,
};
function Login(user, admin = false) {
  return (dispatch) => {
    dispatch(ResponseHandler.request(userConstants.LOGIN_REQUEST));
    RequestService.PostRequest(admin ? "confirmation/admin/login/" : "confirmation/confirmation_agents/login/", user, {}, API_URL)
      .then((res) => {
        Cookies.set("auth", res.data.auth_token);
        dispatch(ResponseHandler.succes({ ...res.data, is_admin: admin }, userConstants.LOGIN_SUCCESS));
      })
      .catch((err) => {
        dispatch(ResponseHandler.failure(err, userConstants.LOGIN_FAILURE));
      });
  };
}
function Register(user) {
  return (dispatch) => {
    dispatch(ResponseHandler.request(userConstants.REGISTER_REQUEST));
    RequestService.PostRequest("confirmation/confirmation_agents/", user, AuthHeader(), API_URL)
      .then((res) => {
        dispatch(ResponseHandler.succes(res.data, userConstants.REGISTER_SUCCESS));
      })
      .catch((err) => {
        dispatch(ResponseHandler.failure(err, userConstants.REGISTER_FAILURE));
      });
  };
}
function GetProfileUser() {
  return (dispatch) => {
    dispatch(ResponseHandler.request(userConstants.GET_USER_REQUEST));

    RequestService.GetRequest("auth/profile/" + token, AuthHeader(), API_URL)
      .then((res) => {
        dispatch(ResponseHandler.succes(res.data.information, userConstants.GET_USER_SUCCESS));
        localStorage.setItem("user_full_name", res?.data?.information?.full_name);
      })
      .catch((err) => {
        dispatch(ResponseHandler.failure(err.response, userConstants.GET_USER_FAILURE));
      });
  };
}
function Logout() {
  return { type: userConstants.LOGOUT };
}

function GetDataUser() {
  return (dispatch) => {
    dispatch(ResponseHandler.request(userConstants.GET_DATA_USER_REQUEST));

    RequestService.GetRequest("confirmation/confirmation_agents/me/", AuthHeader(), API_URL)
      .then((res) => {
        dispatch(ResponseHandler.succes(res.data, userConstants.GET_DATA_USER_SUCCESS));
      })
      .catch((err) => {
        dispatch(ResponseHandler.failure(err.response, userConstants.GET_DATA_USER_FAILURE));
      });
  };
}
