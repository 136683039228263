import React from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import PaginationBar from "src/components/PaginationBar/PaginationBar";
import SnackBar from "src/components/SnackBar/SnackBar";
import "./Orders_refactored.scss";
import globalAdminController from "src/controllers/Admin/global/global.admin.controller";
import OrdersController from "src/controllers/Admin/Orders/Orders.controller";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { components } from "react-select";
import { statusList } from "src/constants/global/statusList";
// import Modal from "src/components/Modal/Modal";
import Modal from "../../../components/Modal/Modal";
import AssignOrders from "src/components/admin/orders/assignOrders/AssignOrders";
import $ from "jquery";
import { CSVLink } from "react-csv";
import moment from "moment";
import "moment/locale/fr";
import { PlayListAddSvg } from "src/components/common/IconSvg";
moment().local("fr");
const Orders_refactored = () => {
  // Main state
  const [data, setData] = useState([]);
  const [orderListResponse, setOrderListResponse] = useState([]);
  const [assignOrdersError, setAssignOrdersError] = useState([]);
  const [assignOrdersBackendError, setAssignOrdersBackendError] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [filterParams, setFilterParams] = useState("");
  const [page, setPage] = useState(1);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
  // Agent list state
  const [agentList, setAgentList] = useState([{}]);
  // Store list state
  const [storesList, setStoresList] = useState([{}]);
  // TODO make a single useState for filters as one object
  // Product name filter
  const [productName, setProductName] = useState("");
  // Customer phone filter
  const [customerPhone, setCustomerPhone] = useState("");
  // Customer name filter
  const [customerName, setCustomerName] = useState("");
  // created at filter
  const [createdAt, setCreatedAt] = useState("");
  // Modals state
  const [isOpenAssignModal, setIsOpenAssignModal] = useState(false);
  // SnackBar state
  const [isOpenSnackBar, setIsOpenSnackBar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  // Backdrop state
  const [isBackDropOpen, setIsBackDropOpen] = useState(false);
  // Export as CSV state
  const [exportData, setExportData] = useState([]);
  // Main methods & properties
  const getData_params = useMemo(() => {
    return {
      setOrdersList: setData,
      setOrderListResponse,
      setAssignOrdersBackendError,
      setIsLoadingData,
      setIsBackDropOpen,
    };
  }, []);
  const getData_orders = OrdersController.getAllOrders;
  // Filter related properties & methods

  const initialValues_filter = {};

  // IF YOU WANT TO CHANGE THE FILTER
  // UPDATE THE PARAMS IN :
  // 1) SUBMIT FUNCTION 'onSubmit_filter' (right under)
  // 2) ASSIGN ORDERS FUNCTION 'onSubmit_assign'
  // 3) EXPORT TO PDF FUNCTION 'onClickCsv'
  // ---
  // You also need to add new search inputs in the UI
  // (sorry for the bricolage)

  const onSubmit_filter = () => {
    const agentId = selectedAgent_filter || "";
    const statusList = selectedStatus || "";
    const storeId = selectedStore_filter || "";
    const productName_filter = productName || "";
    const customerPhone_filter = customerPhone || "";
    const customerName_filter = customerName || "";
    const createdAt_filter = createdAt || "";
    const params = "&confirmation_agent=" + agentId + "&status__in=" + statusList + "&store=" + storeId + "&product_name__icontains=" + productName_filter + "&customer_phone__icontains=" + customerPhone_filter + "&customer_name__icontains=" + customerName_filter + "&created_at__gte=" + createdAt_filter;
    getData_orders(page, params, getData_params).then(() => {
      setFilterParams(params);
      setAssignOrdersError("");
      setIsSelectAllSelected(false);
    });
  };

  const [selectedStore_filter, setSelectedStore_filter] = useState("");
  const [selectedAgent_filter, setSelectedAgent_filter] = useState("");
  const handleSelectAgent_filter = (selected) => {
    setSelectedAgent_filter(selected?.value || "");
  };
  const handleSelectStores_filter = (selected) => {
    setSelectedStore_filter(selected?.value || "");
  };

  // Assign agent related properties & methods

  const isAbleToAssign = (agentId, isSelect, selectedAgent, selectedStatusList, storeId, productName, customerPhone, customerName, ordersList) => {
    let query = "";
    let canAssign = true;
    if (!agentId) {
      setAssignOrdersBackendError("");
      setAssignOrdersError(
        // "Please select the agent you want to assign the orders to "
        "Please select the agent and orders you want to assign "
      );
      canAssign = false;
    }

    if (isSelect) {
      query = `?confirmation_agent=${selectedAgent}&status__in=${selectedStatusList}&store=${storeId}&product_name__icontains=${productName}&customer_phone__icontains=${customerPhone}&customer_name__icontains=${customerName}`;
    } else {
      if (ordersList.length === 0) {
        setAssignOrdersBackendError("");
        setAssignOrdersError("Please select the orders you want to assign");
        canAssign = false;
      }
    }
    return { canAssign, query };
  };
  const initialValues_assign = {};
  const onSubmit_assign = (values) => {
    const assignOrders_params = {
      setIsOpenSnackBar,
      setIsOpenAssignModal,
      setSnackBarSeverity,
      setSnackBarMessage,
      setAssignOrdersError,
      setAssignOrdersBackendError,
      setIsBackDropOpen,
    };
    const ordersList = values.assigningOrdersList;
    const agentName = $("#agentList_Input").val();
    const agentId = $('#agentList [value="' + agentName + '"]').data("value");
    const statusLabel = $("#statusList_Input").val();
    const statusId = $('#statusList [value="' + statusLabel + '"]').data("value");
    const status = statusId || "";
    const selectedStatusList = selectedStatus || "";
    const storeId = selectedStore_filter || "";
    const isSelectAll = isSelectAllSelected || false;
    const selectedAgent = selectedAgent_filter || "";
    const productName_filter = productName || "";
    const customerPhone_filter = customerPhone || "";
    const customerName_filter = customerName || "";
    const createdAt_filter = createdAt || "";
    const { canAssign, query } = isAbleToAssign(agentId, isSelectAll, selectedAgent, selectedStatusList, storeId, productName_filter, customerPhone_filter, customerName_filter, ordersList);
    if (canAssign) {
      const assignOrdersToAgent = OrdersController.AssignOrdersToAgent;
      assignOrdersToAgent(ordersList, agentId, status, query, assignOrders_params).then(() => {
        getData_orders(page, filterParams, getData_params);
        setAssignOrdersBackendError("");
        setAssignOrdersError("");
      });
    }
  };
  // misc methods

  const getAllAgents = globalAdminController.getAllAgents;
  const getAllStores = globalAdminController.getAllStores;
  const getAgent_params = useMemo(() => {
    return { setAgentList };
  }, []);
  const getStore_params = useMemo(() => {
    return { setStoresList };
  }, []);

  useEffect(() => {
    if (isLoadingData) {
      getData_orders(1, "", getData_params);
    }
    getAllAgents(getAgent_params);
    getAllStores(getStore_params);
  }, [getData_orders, getData_params, isLoadingData, getAllAgents, getAllStores, getAgent_params, getStore_params]);

  const handlePageChange = (event, value) => {
    setPage(value);
    getData_orders(value, filterParams, getData_params);
  };

  const handleOpenAssignOrdersModal = () => {
    setIsOpenAssignModal(true);
  };

  const csvRef = useRef(null);
  const onClickCsv = async () => {
    const export_params = {
      setExportData,
      setIsOpenSnackBar,
      setSnackBarSeverity,
      setSnackBarMessage,
      setIsBackDropOpen,
    };
    let btn = csvRef.current;
    const agentId = selectedAgent_filter || "";
    const statusList = selectedStatus || "";
    const storeId = selectedStore_filter || "";
    const productName_filter = productName || "";
    const customerPhone_filter = customerPhone || "";
    const customerName_filter = customerName || "";
    const createdAt_filter = createdAt || "";
    const exportQuery = "?confirmation_agent=" + agentId + "&status__in=" + statusList + "&store=" + storeId + "&product_name__icontains=" + productName_filter + "&customer_phone__icontains=" + customerPhone_filter + "&customer_name__icontains=" + customerName_filter + "&created_at__gte=" + createdAt_filter;
    const exportCSV = OrdersController.getExportCSV;
    await exportCSV(exportQuery, export_params);
    await btn.link.click();
  };
  const Option = (props) => {
    return (
      <div className="reactSelect_customOption_checkbox">
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const [selectedStatus, setSelectedStatus] = useState("");
  const handleChange_check = (selected) => {
    // map functions to only return the value (id)
    // of the status.
    // That's becaused the 'selected' object returns
    // an array with objects which have
    // a 'value' and 'label' property, and here we
    // only need to return the status id, so the 'value'
    // property of the object.

    setSelectedStatus(
      selected.map((element) => {
        return element?.value;
      })
    );
  };

  const handleSelectAllChange = () => {
    setIsSelectAllSelected(!isSelectAllSelected); // Comment.1

    if (isSelectAllSelected) {
      setAssignOrdersError(""); // Comment.2
    }

    if (!isSelectAllSelected) {
      // if the user has selected the agent to assign the orders to
      setAssignOrdersError(
        // Comment.2
        (current) => current + "\n" + `Your are about to assign all the selected orders (${orderListResponse?.data?.count}) to the selcted agent`
      );
    }
    // Comment.1
    // if the user clicks on the checkbox, it updates
    // the boolean value of the checkbox.
    // Comment.2
    // This displays a warning to let the user know
    // that he/she will assign all the orders
  };

  const handleChangeProductName = (event) => {
    setProductName(event.target.value);
  };
  const handleChangeCustomerName = (event) => {
    setCustomerName(event.target.value);
  };
  const handleChangePhoneName = (event) => {
    setCustomerPhone(event.target.value);
  };
  const handleChangeCreatedAt = (event) => {
    setCreatedAt(event.target.value);
  };

  return (
    <div className="admin_ordersPage_refactored">
      <SnackBar openSnackBar={{ isOpenSnackBar, setIsOpenSnackBar }} snackBarSeverity={snackBarSeverity} snackBarMessage={snackBarMessage} />
      <Backdrop sx={{ zIndex: 1000 }} open={isBackDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <header>
        <h2>Orders</h2>
      </header>
      <main>
        <section className="admin_ordersPage_actions">
          <section className="actions_filter">
            <Formik initialValues={initialValues_filter} onSubmit={onSubmit_filter} validator={() => ({})}>
              <Form>
                <article>
                  <section>
                    <Select
                      className="reactSelect"
                      options={agentList.map((agent) => {
                        return { label: agent?.full_name, value: agent?.id };
                      })}
                      placeholder="Agent"
                      isClearable
                      onChange={handleSelectAgent_filter}
                    />
                  </section>
                  <section>
                    <Select
                      id="statusList_select"
                      className="reactSelect-status"
                      options={statusList}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                      }}
                      placeholder={"Status"}
                      onChange={handleChange_check}
                      // allowSelectAll={true}
                    />
                  </section>
                  <section>
                    <Select
                      className="reactSelect"
                      options={storesList.map((store) => {
                        return { label: store?.name, value: store?.id };
                      })}
                      placeholder="Store"
                      isClearable
                      onChange={handleSelectStores_filter}
                    />
                  </section>
                  <section>
                    <input className="filterInput" type="text" placeholder="Product name" name="productName" value={productName} onChange={handleChangeProductName} />
                  </section>
                  <section>
                    <input className="filterInput" type="text" placeholder="Customer name" name="customerName" value={customerName} onChange={handleChangeCustomerName} />
                  </section>
                  <section>
                    <input className="filterInput" type="text" placeholder="Customer phone" name="customerPhone" value={customerPhone} onChange={handleChangePhoneName} />
                  </section>
                  <section>
                    <input className="filterInput" type="date" placeholder="Created at" name="createdAt" value={createdAt} onChange={handleChangeCreatedAt} />
                  </section>
                </article>

                <button type="submit">Search</button>
              </Form>
            </Formik>
          </section>
          <section className="actions_buttonContainer">
            <button className="assignButton" onClick={handleOpenAssignOrdersModal}>
              Assign
            </button>

            <button className="exportButton" onClick={onClickCsv}>
              Export
              {/* {Translate("actions", "export")} */}
              {PlayListAddSvg}
            </button>

            <CSVLink filename={`orders_export_${moment().format("LL")}.csv`} className="exportButton" ref={csvRef} data={exportData} style={{ display: "none" }}>
              <span className="material-icons">upload</span>
              Exporter
            </CSVLink>
          </section>
        </section>
        <h4
          style={{
            visibility: isSelectAllSelected ? "visible" : "hidden",
            marginTop: "10px",
            color: "red",
          }}
        >
          You have selected all the orders !
        </h4>
        <article className="admin_ordersPage_ordersTable">
          <Formik initialValues={initialValues_assign} onSubmit={onSubmit_assign} validator={() => ({})}>
            <Form>
              <header className="pagination">
                <PaginationBar totalPages={Math.ceil(orderListResponse?.data?.count / 20)} totalCount={orderListResponse?.data?.count} page={page} resultsLength={orderListResponse?.data?.results?.length} setPage={setPage} handlePageChange={handlePageChange} />
              </header>

              <article className="adminOrders_tableContainer">
                {isLoadingData ? (
                  ""
                ) : (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <Field className="adminOrders_checkbox" type="checkbox" name="selectAllOrders_checkbox" id={"selectAllOrders"} checked={isSelectAllSelected} onChange={handleSelectAllChange} />
                            <label htmlFor={"selectAllOrders"}></label>
                          </th>
                          <th>ID</th>
                          <th>Status</th>
                          <th>Created at</th>
                          <th>Last update</th>
                          <th>Cancellation reason</th>
                          <th>Customer</th>
                          <th>Phone</th>
                          <th>Products</th>
                          <th>Store</th>
                          <th>Agent</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((order, index) => {
                          const statusColor = order?.status === "Confirmed" ? "#1bb99a" : order?.status === "Cancelled" ? "#dc3545" : order?.status === "Callback" ? "#007bff" : order?.status === "Pending" ? "#fac710" : order?.status === "Postponed" ? "#a436ac" : "";
                          return (
                            <tr key={index}>
                              <td>
                                {!(order.status === "Confirmed") && !isSelectAllSelected && (
                                  <>
                                    <Field className="adminOrders_checkbox" type="checkbox" name="assigningOrdersList" id={order.id} value={order.id} />
                                    <label htmlFor={order.id}></label>
                                  </>
                                )}
                              </td>
                              <td>{order?.external_id}</td>
                              <td style={{ color: statusColor }}>{order?.status}</td>
                              <td>{moment(order?.created_at).format("YYYY-MM-DD")}</td>
                              <td>{moment(order?.history[0]?.created_at).format("YYYY-MM-DD, h:mm a")}</td>
                              <td>{order?.cancellation_reason}</td>
                              <td>{order?.customer_name}</td>
                              <td>{order?.customer_phone}</td>
                              <td>{order?.product}</td>
                              <td>{order?.store?.name}</td>
                              <td>{order?.confirmation_agent?.full_name}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </article>

              {isOpenAssignModal && (
                <Modal
                  Component={AssignOrders}
                  closeModal={{
                    isOpenModal: isOpenAssignModal,
                    setIsOpenModal: setIsOpenAssignModal,
                  }}
                  component_props={{
                    agentList,
                    assignOrdersBackendError,
                    assignOrdersError,
                  }}
                />
              )}
            </Form>
          </Formik>
        </article>
      </main>
    </div>
  );
};

export default Orders_refactored;
