import axios from "../../http-common";
import Cookies from "js-cookie";

export const getPostponedOrder = (phone) => {
  return axios.get(
    `confirmation/agents/orders_2b_confirmed/?status__in=20,30,100&customer_phone__icontains=${phone}`,
    {
      headers: { Authorization: `Token ${Cookies.get("auth")}` },
    }
  );
};

export const getAssignedOrders = (phone) =>{
  return axios.get(
    `confirmation/agents/orders_2b_confirmed/?status__in=20,30,100&customer_phone__icontains=${phone}`,
    {
      headers: { Authorization: `Token ${Cookies.get("auth")}` },
    }
  );
}
export const getCancellationReasons = () => {
  return axios.get("shared/abort_reasons/", {
    headers: { Authorization: `Token ${Cookies.get("auth")}` },
  });
};

export const getNextOrder = async () => {
  return await axios.get("confirmation/agents/orders_2b_confirmed/next/", {
    headers: { Authorization: `Token ${Cookies.get("auth")}` },
  });
};

export const confirmOrder = (orderId, order) => {
  return axios.post(
    `confirmation/agents/orders_2b_confirmed/${orderId}/confirm/`,
    {
      ...order,
    },
    {
      headers: { Authorization: `Token ${Cookies.get("auth")}` },
    }
  );
};

export const callbackOrder = (orderId, order) => {
  return axios.post(
    `confirmation/agents/orders_2b_confirmed/${orderId}/callback/`,
    {
      ...order,
    },
    {
      headers: { Authorization: `Token ${Cookies.get("auth")}` },
    }
  );
};

export const cancelOrder = (orderId, order) => {
  return axios.post(
    `/confirmation/agents/orders_2b_confirmed/${orderId}/cancel/
  `,
    {
      ...order,
    },
    {
      headers: { Authorization: `Token ${Cookies.get("auth")}` },
    }
  );
};

export const postponeOrder = (orderId, order) => {
  return axios.post(
    `confirmation/agents/orders_2b_confirmed/${orderId}/postpone/`,
    {
      ...order,
    },
    {
      headers: { Authorization: `Token ${Cookies.get("auth")}` },
    }
  );
};
const Services = {
  getNextOrder,
  getPostponedOrder,
  getAssignedOrders,
  getCancellationReasons,
  confirmOrder,
  callbackOrder,
  cancelOrder,
  postponeOrder,
};
export default Services;
