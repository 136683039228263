import { combineReducers } from 'redux';
import {authentication} from './authentication.reducer'
import {dashboard} from './dashboard.reducer'
import {statistics} from './stats.reducer'
import {orders} from './order.reducer'
import {alert} from './alert.reducer'
import {common} from './common.reducer'
import {user} from './user.reducer'
import {session} from './session.reducer'
import {call} from './call.reducer'
import {listener} from './Listener.reducer'

const rootReducer = combineReducers({
  authentication,
  dashboard,
  statistics,
  orders,
  alert,
  common,
  user,
  session,
  call,
  listener
});

export default rootReducer;